import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import swal from 'sweetalert';














const SubCategoriesProduct = (props)=> {
    const [CategoriesUrl , setCategoriesUrl] = useState("")
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [CategoriesName , setCategoriesname] = useState({})
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [subCate , setsubCate] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [Subproduct1 , setSubproduct1] = useState([])
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [dispaluy53 , setdispaluy53] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    // categoriesName subcategories

    useEffect(() =>{

        window.scrollTo(0, 0)
        // console.log( props.match.params);
        let Categoriesname = props.match.params ;
        setCategoriesname(Categoriesname)

        // const Inter = setInterval(()=>{
            //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
            //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

        // var Category =  props.match.url.split("/")
        // setCategoriesUrl(data1)
        // var Category1 =  CategoriesUrl.split("-").join(" ")
        if( JSON.parse(localStorage.getItem("Prod"))  ){
          setallproduct(JSON.parse(localStorage.getItem("Prod")))
          const NonActive = JSON.parse(localStorage.getItem("Prod")).filter((res9,i)=>{
              if(res9.status   ){
                  return res9 
              }
          })

          setSubproduct(NonActive)
          console.log(NonActive)

        }
        else{
          fetch("https://garden-oasis-landscapping-main-back.vercel.app/AllProduct",{
                      method: "GET",
                      headers :  {
                      "Content-Type" : "application/json" , 
                  } ,
                  })
                  .then(res7=>res7.json())
                  .then(res8=>{
                      setallproduct(res8)
                      const NonActive = res8.filter((res9,i)=>{
                          if(res9.status   ){
                              return res9 
                          }
                      })
  
                      setSubproduct(NonActive)
                      console.log(NonActive)
                  })

        }


},[])


    const setSortPrice1 = (e)=>{
      setdispaluy53(!dispaluy53)
      if(Subproduct.length !== 0){

        SortedName.push(e)
        console.log(e,Subproduct);
        if (e === "Low"){
            const Pro6 = Subproduct.sort(function (a, b) {
                    return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                  });
                  console.log(Pro6);
                  let reversed_array = [];
                  Pro6.forEach((element) => {
                    reversed_array.unshift(element);
                });
                  console.log(Pro6,reversed_array)
                  setSubproduct(reversed_array)
                  setSubproduct(Pro6)
                  setdispaluy53(!dispaluy53)
                  setdispaluy53(!dispaluy53)
    
        }
        if (e === "High"){
            const Pro6 = Subproduct.sort((a, b)=> {
                    return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                    
                });
              //   let reversed_array = [];
              //   Pro6.forEach((element) => {
              //     reversed_array.unshift(element);
              // });
              //   console.log(Pro6,reversed_array)
                setSubproduct(Pro6)
                setdispaluy53(!dispaluy53)
                setdispaluy53(!dispaluy53)

        }
        if (e === "A"){
            const Pro6 = Subproduct.sort((a, b)=> {
                var nameA = a.Product_Name.toUpperCase(); 
                var nameB = b.Product_Name.toUpperCase(); 
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
    
                return 0;
                });
                console.log(Pro6);
                setSubproduct(Pro6)
                
              }
              
              setSubproduct1([])
              setdispaluy53(!dispaluy53)

            }
            else{
        
                SortedName.push(e)
                console.log(e);
                if (e === "Low"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6)
            
                }
                if (e === "High"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6.reverse())
            
                }
                if (e === "A"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                        var nameA = a.Product_Name.toUpperCase(); 
                        var nameB = b.Product_Name.toUpperCase(); 
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
            
                        return 0;
                        });
                        console.log(Pro6);
                        setSubproduct1(Pro6)
                        
                      }
                      
                      
                      setSubproduct([])
                    }
                    setdispaluy53(!dispaluy53)

                  }
  

  
const addtocartproduct = (productDetail) =>{
  swal("Update!", "Your Item Add in Cart!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }


  }


      

}


const addtocartproduct8 = (productDetail) =>{
    swal("Update!", "Item Save!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  
        return (
       




<>
          <main className="main">
          <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">{CategoriesName.categoriesName}<span>Shop</span></h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index-32.html">Home</a></li>
                <li className="breadcrumb-item"><a >Shop</a></li>
                <li className="breadcrumb-item active" aria-current="page">{CategoriesName.categoriesName}</li>
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="toolbox">
                    <div className="toolbox-left">
                      <div className="toolbox-info">
                        Showing <span>{Subproduct.length} of {Subproduct.length}</span> Products
                      </div>{/* End .toolbox-info */}
                    </div>{/* End .toolbox-left */}
                    <div className="toolbox-right">
                      <div className="toolbox-sort">
                        <label htmlFor="sortby">Sort by:</label>
                        <div className="select-custom">
                          <select name="sortby" id="sortby" className="form-control">
                            <option value="popularity" selected="selected">Most Popular</option>
                            <option value="Low" onClick={()=>setSortPrice1("Low")}>Low → High</option>
                          <option value="High" onClick={()=>setSortPrice1("High")}>High → Low</option>
                          <option value="A" onClick={()=>setSortPrice1("A")}>A → Z</option>
                            <option value="rating">Most Rated</option>
                            <option value="date">Date</option>
                          </select>
                        </div>
                      </div>{/* End .toolbox-sort */}
                      
                    </div>{/* End .toolbox-right */}
                  </div>{/* End .toolbox */}
                  <div className="products mb-3">
                    <div className="row justify-content-center">


             
{Subproduct.map((res,i)=>{
            if(res.Course_Sub_Catagories !== ""){
                return(
<div className="col-6 col-md-4 col-lg-4 col-xl-3">

<div className="product d-flex flex-column overflow-hidden item">
                          <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          {/* <span className="product-label label-sale">SALE</span> */}

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="w-100">
                              <img src={res.Product_Image_Upload}  className="product-image" width={192} height={192} />
                              <img src={res.Product_Image_Upload1}  className="product-image-hover" width={192} height={192} />
                            </Link>
                            <div className="product-action-vertical">
                              <a className="btn-product-icon text-dark btn-wishlist" title="Add to wishlist" onClick={()=>addtocartproduct8([res])}>
                                <span>add to wishlist</span>
                              </a>
                              {/* <a href="popup/quickView.html" className="btn-product-icon text-dark btn-quickview" title="Quick view">
                                <span>Quick view</span>
                              </a>
                              <a  className="btn-product-icon text-dark btn-compare" title="Compare">
                                <span>Compare</span>
                              </a> */}
                              <a className="btn-product-icon text-dark btn-cart" title="Cart" onClick={()=>addtocartproduct([res])}>
                              <span>add to cart</span>
                              </a>

                            </div>
                            {/* End .product-action-vertical */}
                          </figure>
                          {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                          <div className="product-body">
                            <div className="product-cat mb-0 text-light text-left">
                              <a>{res.Product_Catagories}</a>
                            </div>
                            {/* End .product-cat  */}
                            <h3 className="product-title letter-spacing-normal font-size-normal mb-0 text-left" style={{maxWidth:"100%",maxHeight : "46px",overflow : "hidden"}}>
                              <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                            </h3>
                            {/* End .product-title letter-spacing-normal font-size-normal */}
                            {res.Product_Price_Discounted ? 
                            <div className="product-price mb-1">
                        <div className="new-price">AED {res.Product_Price_Discounted}</div>
                        <div className="old-price font-size-normal font-weight-normal">AED {(res.Product_Price)}</div>
                      </div>
                      :
                            <div className="product-price mb-1 text-dark">
                             AED {(res.Product_Price)}
                            </div>
            }
                            {/* End .product-price */}
                            {/* End .rating-container */}
                            
                            {/* End .product-nav */}
                          </div>
                          {/* End .product-body */}
                          <div className="product-action position-relative visible">
                            <a className="btn-product btn-cart text-uppercase text-dark text-decoration-none" title="Add to cart" onClick={()=>addtocartproduct([res])}>
                              <span className="text-dark shadow-none">add to cart</span>
                            </a>
                          </div>
                          {/* End .product-action */}
                        </div>
                        </div>

                )
            }
            if(res.Product_Sub_Catagories !== ""){
              console.log("323")
              return(
            
                <div className="col-6 col-md-4 col-lg-4 col-xl-3">

                <div className="product d-flex flex-column overflow-hidden">
                          <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          {/* <span className="product-label label-sale">SALE</span> */}

                            <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="w-100">
                              <img src={res.Product_Image_Upload}  className="product-image" width={192} height={192} />
                              <img src={res.Product_Image_Upload1}  className="product-image-hover" width={192} height={192} />
                            </Link>
                            <div className="product-action-vertical">
                              <a className="btn-product-icon text-dark btn-wishlist" title="Add to wishlist" onClick={()=>addtocartproduct8([res])}>
                                <span>add to wishlist</span>
                              </a>
                              {/* <a href="popup/quickView.html" className="btn-product-icon text-dark btn-quickview" title="Quick view">
                                <span>Quick view</span>
                              </a>
                              <a className="btn-product-icon text-dark btn-compare" title="Compare">
                                <span>Compare</span>
                              </a> */}
                              <a className="btn-product-icon text-dark btn-cart" title="Cart" onClick={()=>addtocartproduct([res])}>
                              <span>add to cart</span>
                              </a>  
                            </div>
                            {/* End .product-action-vertical */}
                          </figure>
                          {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                          <div className="product-body">
                            <div className="product-cat mb-0 text-light text-left">
                              <a>{res.Product_Catagories}</a>
                            </div>
                            {/* End .product-cat  */}
                            <h3 className="product-title letter-spacing-normal font-size-normal mb-0 text-left" style={{maxWidth:"100%",maxHeight : "46px",overflow : "hidden"}}>
                              <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                            </h3>
                            {/* End .product-title letter-spacing-normal font-size-normal */}
                            {res.Product_Price_Discounted ? 
                            <div className="product-price mb-1">
                        <div className="new-price">AED {res.Product_Price_Discounted}</div>
                        <div className="old-price font-size-normal font-weight-normal">AED {(res.Product_Price)}</div>
                      </div>
                      :
                            <div className="product-price mb-1 text-dark">
                             AED {(res.Product_Price)}
                            </div>
            }
                            {/* End .product-price */}
                            {/* End .rating-container */}
                            
                            {/* End .product-nav */}
                          </div>
                          {/* End .product-body */}
                          <div className="product-action position-relative visible">
                          <a className="btn-product btn-cart text-uppercase text-dark text-decoration-none" title="Add to cart" onClick={()=>addtocartproduct([res])}>
                              <span className="text-dark shadow-none">add to cart</span>
                            </a>
                          </div>
                          {/* End .product-action */}
                        </div>
                        </div>
                                )
            }
            else{
              return(
                <div className="col-6 col-md-4 col-lg-4 col-xl-3">

                <div className="product d-flex flex-column overflow-hidden">
                <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                {/* <span className="product-label label-sale">SALE</span> */}

                  <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="w-100">
                  <img src={res.Product_Image_Upload}  className="product-image" width={192} height={192} />
                              <img src={res.Product_Image_Upload1}  className="product-image-hover" width={192} height={192} />
                            </Link>
                            <div className="product-action-vertical">
                              <a className="btn-product-icon text-dark btn-wishlist" title="Add to wishlist" onClick={()=>addtocartproduct8([res])}>
                                <span>add to wishlist</span>
                              </a>
                              {/* <a href="popup/quickView.html" className="btn-product-icon text-dark btn-quickview" title="Quick view">
                                <span>Quick view</span>
                              </a>
                              <a className="btn-product-icon text-dark btn-compare" title="Compare">
                                <span>Compare</span>
                              </a> */}
                              <a className="btn-product-icon text-dark btn-cart" title="Cart" onClick={()=>addtocartproduct([res])}>
                              <span>add to cart</span>
                              </a>

                            </div>
                  {/* End .product-action-vertical */}
                </figure>
                {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                <div className="product-body">
                  <div className="product-cat mb-0 text-light text-left">
                    <a>{res.Product_Catagories}</a>
                  </div>
                  {/* End .product-cat  */}
                  <h3 className="product-title letter-spacing-normal font-size-normal mb-0 text-left" style={{maxWidth:"100%",maxHeight : "46px",overflow : "hidden"}}>
                    <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                  </h3>
                  {/* End .product-title letter-spacing-normal font-size-normal */}
                  {res.Product_Price_Discounted ? 
                            <div className="product-price mb-1">
                        <div className="new-price">AED {res.Product_Price_Discounted}</div>
                        <div className="old-price font-size-normal font-weight-normal">AED {(res.Product_Price)}</div>
                      </div>
                      :
                            <div className="product-price mb-1 text-dark">
                             AED {(res.Product_Price)}
                            </div>
            }
                  {/* End .product-price */}
                  {/* End .rating-container */}
                  
                  {/* End .product-nav */}
                </div>
                {/* End .product-body */}
                <div className="product-action position-relative visible">
                <a className="btn-product btn-cart text-uppercase text-dark text-decoration-none" title="Add to cart" onClick={()=>addtocartproduct([res])}>
                              <span className="text-dark shadow-none">add to cart</span>
                            </a>
                </div>
                {/* End .product-action */}
              </div>
              </div>
                
                                )
            }
              
              })
                
              }


                      
                    </div>{/* End .row */}
                  </div>{/* End .products */}
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className="page-item disabled">
                        <a className="page-link page-link-prev"  aria-label="Previous" tabIndex={-1} aria-disabled="true">
                          <span aria-hidden="true"><i className="icon-long-arrow-left" /></span>Prev
                        </a>
                      </li>
                      <li className="page-item active" aria-current="page"><a className="page-link" >1</a></li>
                      {/* <li className="page-item"><a className="page-link" >2</a></li>
                      <li className="page-item"><a className="page-link" >3</a></li>
                      <li className="page-item-total">of 6</li> */}
                      <li className="page-item">
                        <a className="page-link page-link-next"  aria-label="Next">
                          Next <span aria-hidden="true"><i className="icon-long-arrow-right" /></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>{/* End .col-lg-9 */}
                
              </div>{/* End .row */}
            </div>{/* End .container */}
          </div>{/* End .page-content */}
        </main>{/* End .main */}




</>
        )
    }



export default SubCategoriesProduct;