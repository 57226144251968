import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Ico from './iconBox';

import a1 from '../assets/images/7138294.jpg';
import a2 from '../assets/images/9417839.jpg';
import a3 from '../assets/images/9535087.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
         

        

<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-lg-9 col-sm-12" style={{margin  :"auto"}}>


            <header>
		<h1>Get High-Quality Artificial Grass and Transform Your Outdoor Space at Our Store</h1>
	</header>
  <img src={a1} alt="Benefits of Annual Maintenance Contracts for Your Garden Plant in Dubai with Garden Oasis Landscape Service" />
<br />

	<main>
		<section>
			<h2>The Benefits of Artificial Grass</h2>
			<ul>
				<li><strong>Low Maintenance</strong>: Artificial grass requires minimal upkeep, eliminating the need for watering, mowing, and fertilizing.</li>
				<li><strong>Drought Tolerance</strong>: Artificial grass can withstand Dubai's hot and dry climate, making it an ideal choice for water-conscious individuals.</li>
				<li><strong>Durability</strong>: Artificial grass can last for up to 20 years, making it a cost-effective solution in the long run.</li>
				<li><strong>Pest-Free</strong>: Artificial grass is resistant to pests and diseases, reducing the need for pesticides and other chemicals.</li>
				<li><strong>Aesthetically Pleasing</strong>: Artificial grass comes in a variety of colors and textures, allowing you to create a beautiful and unique outdoor space.</li>
			</ul>
		</section>
<br />
		<section>
			<h2>Advantages of Artificial Grass over Natural Grass</h2>
			<ul>
				<li><strong>Water Conservation</strong>: Artificial grass requires significantly less water than natural grass, making it an eco-friendly choice.</li>
				<li><strong>Year-Round Use</strong>: Artificial grass can be used year-round, regardless of the weather conditions.</li>
				<li><strong>No Mud or Mess</strong>: Artificial grass eliminates the risk of mud and mess, making it ideal for families with pets or children.</li>
				<li><strong>No Pests or Diseases</strong>: Artificial grass is resistant to pests and diseases, reducing the need for pesticides and other chemicals.</li>
			</ul>
		</section>
    <div className="container icon-boxes-section" style={{background:"#286C53"}}>
                <div className="icon-boxes-container py-4 pb-2 pt-2" style={{background:"#286C53"}}>

                  <div className='row' style={{background:"#286C53"}}>
                 

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        {/* <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Payment &amp; Delivery</h3> */}
                      </div>
                    </div>

<div className="col-lg-5 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 text-uppercase" style={{color : "white",fontSize:"18px",fontWeight:"500"}}>Gardening and Landscaping Services in Dubai
</h3>
                      </div>
                    </div>

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      
                    </div>

<div className="col-lg-3 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px",margin:"20px 0px"}} ><Link to="/contact" >Get a free quote</Link></button>
                      </div>
                    </div>

<div className="col-lg-2 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px"}} ><a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" >Whatsapp</a></button>
                      </div>
                    </div>

                    
                    </div>
                    </div>
                    </div>
<br />
		<section>
			<h2>Our Store: Your One-Stop Shop for Artificial Grass Solutions</h2>
			<p>At our store, we offer a wide range of artificial grass solutions to suit your specific needs and preferences. Our team of experts will work with you to create a customized outdoor space that meets your requirements and budget.</p>
			<ul>
				<li><strong>Artificial Grass Installation</strong>: Our team of experts will install high-quality artificial grass in your outdoor space, ensuring a seamless and professional finish.</li>
				<li><strong>Artificial Grass Supply</strong>: We offer a wide range of artificial grass products, including grass tiles, rolls, and mats.</li>
				<li><strong>Outdoor Space Design</strong>: Our team of designers will work with you to create a customized outdoor space that meets your needs and preferences.</li>
			</ul>
		</section>
<br />
		<section>
			<h2>Why Choose Our Store?</h2>
			<ul>
				<li><strong>High-Quality Products</strong>: We offer high-quality artificial grass products that are durable, aesthetically pleasing, and eco-friendly.</li>
				<li><strong>Expert Installation</strong>: Our team of experts has years of experience in installing artificial grass, ensuring a seamless and professional finish.</li>
				<li><strong>Customized Solutions</strong>: We offer customized outdoor space design and installation services to meet your specific needs and preferences.</li>
				<li><strong>Sustainable Practices</strong>: We are committed to sustainable practices, reducing waste and promoting eco-friendly solutions.</li>
			</ul>
		</section>
	</main>


    <div className="container icon-boxes-section" style={{background:"#286C53"}}>
                <div className="icon-boxes-container py-4 pb-2 pt-2" style={{background:"#286C53"}}>

                  <div className='row' style={{background:"#286C53"}}>
                 

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        {/* <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Payment &amp; Delivery</h3> */}
                      </div>
                    </div>

<div className="col-lg-5 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 text-uppercase" style={{color : "white",fontSize:"18px",fontWeight:"500"}}>Gardening and Landscaping Services in Dubai
</h3>
                      </div>
                    </div>

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      
                    </div>

<div className="col-lg-3 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px",margin:"20px 0px"}} ><Link to="/contact" >Get a free quote</Link></button>
                      </div>
                    </div>

<div className="col-lg-2 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px"}} ><a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" >Whatsapp</a></button>
                      </div>
                    </div>

                    
                    </div>
                    </div>
                    </div>





          
          </div>
          </div>
          </div>



          </div>
        )
    }
}

export default About;