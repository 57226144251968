import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Ico from './iconBox';

import a1 from '../assets/images/7138294.jpg';
import a2 from '../assets/images/9417839.jpg';
import a3 from '../assets/images/9535087.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
         

        

<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-lg-9 col-sm-12" style={{margin  :"auto"}}>

            <header>
		<h1 style={{fontSize:"3rem",fontWeight:"600"}}>What is Landscape Design?</h1>
	</header>
    <img src={a2} alt="What is Landscape Design?" />
<br />

	<main>
		<section>
			<h2>Landscape Design Definition</h2>
			<p>Landscape design is the name given to all the arrangements made in a garden, which generally stands out with its aesthetic appearance and stands out with its function and maintenance process.</p>
		</section>
<br />
		<section>
			<h2>Why Landscape Design?</h2>
			<p>Landscape designs are made especially for their aesthetic and decorative features. Almost everyone wants to have a stunning and functional garden.</p>
		</section>
<br />
		<section>
			<h2>Landscape Adds Value</h2>
			<p>Landscaping is also very valuable for businesses such as restaurants, shopping centers and hotels. While this type of arrangement reflects the value and culture of your brand, it also offers visitors an area where they can relax and satisfy their longing for nature.</p>
		</section>
<br />
		<section>
			<h2>Makes You Feel Better</h2>
			<p>In environments such as hospitals, it will make patients feel much better. Landscaping in residential gardens can reflect your personality and provide an environment for all kinds of birds.</p>
		</section>
        
    <div className="container icon-boxes-section" style={{background:"#286C53"}}>
                <div className="icon-boxes-container py-4 pb-2 pt-2" style={{background:"#286C53"}}>

                  <div className='row' style={{background:"#286C53"}}>
                 

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        {/* <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Payment &amp; Delivery</h3> */}
                      </div>
                    </div>

<div className="col-lg-5 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 text-uppercase" style={{color : "white",fontSize:"18px",fontWeight:"500"}}>Gardening and Landscaping Services in Dubai
</h3>
                      </div>
                    </div>

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      
                    </div>

<div className="col-lg-3 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px",margin:"20px 0px"}} ><Link to="/contact" >Get a free quote</Link></button>
                      </div>
                    </div>

<div className="col-lg-2 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px"}} ><a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" >Whatsapp</a></button>
                      </div>
                    </div>
                    </div>
                    </div>
                    </div>
<br />
		<section>
			<h2>How Should Landscape Design Be Done?</h2>
			<p>Landscape design construction involves a very complex process. It should be said from the beginning that this is an art.</p>
		</section>
<br />
		<section>
			<h2>Landscape Design And Form Relationship</h2>
			<p>Form is an important issue related to how the environment will be perceived. Form includes almost every subject from the size of flowers to grass areas, from the use of stones to symmetry.</p>
		</section>
<br />
		<section>
			<h2>Vertical Gardening In Design</h2>
			<p>Many different styles and structures can be used in landscape designs. One of the prominent ones is vertical gardens.</p>
		</section>
<br />
	</main>

    <div className="container icon-boxes-section" style={{background:"#286C53"}}>
                <div className="icon-boxes-container py-4 pb-2 pt-2" style={{background:"#286C53"}}>

                  <div className='row' style={{background:"#286C53"}}>
                 

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        {/* <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Payment &amp; Delivery</h3> */}
                      </div>
                    </div>

<div className="col-lg-5 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 text-uppercase" style={{color : "white",fontSize:"18px",fontWeight:"500"}}>Gardening and Landscaping Services in Dubai
</h3>
                      </div>
                    </div>

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      
                    </div>

<div className="col-lg-3 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px",margin:"20px 0px"}} ><Link to="/contact" >Get a free quote</Link></button>
                      </div>
                    </div>

<div className="col-lg-2 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px"}} ><a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" >Whatsapp</a></button>
                      </div>
                    </div>

                    
                    </div>
                    </div>
                    </div>





          
          </div>
          </div>
          </div>



          </div>
        )
    }
}

export default About;