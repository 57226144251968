import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import OwlCarousel from 'react-owl-carousel';  
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";




import swal from 'sweetalert';
// import Loader from "react-loader-spinner";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Container, Card, Row, Col } from "react-bootstrap";
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import logo1 from '../assets/images/logo1.png';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import KIT1 from '../assets/images/KIT.jpg';
// import slid2 from '../assets/images/slid2.webp';


// import ch1 from '../asset1/images/demos/demo-26/banners/banner-5.jpg';
// import ch2 from '../asset1/images/demos/demo-26/banners/banner-6.jpg';
// import ch3 from '../asset1/images/demos/demo-26/bg-1.jpg';

import slid1 from '../assets/images/slider (1).jpg';
import slid2 from '../assets/images/slider (2).jpg';
import slid3 from '../assets/images/slider (3).jpg';
import slid4 from '../assets/images/slider (4).jpg';
import slid5 from '../assets/images/slider (5).jpg';
import slid6 from '../assets/images/slider6.jpg';
import slid from '../assets/images/featured-image-backyard-landscaping.webp';
import sli from '../assets/images/indoor.webp';
import sli0 from '../assets/images/prog.jpg';
import sli1 from '../assets/images/amc_before_c0c91c48-f3bc-42a5-8cce-7b4cd438cab2.webp';
import sli2 from '../assets/images/img16.jpg';
import sli3 from '../assets/images/20170525_161104.webp';
import sli4 from '../assets/images/Winter_2048x2048.webp';
import sli5 from '../assets/images/Garden-Design-with-Raised-Planters.jpg';
import sli6 from '../assets/images/Irrigation-maintenance.jpg';
import sli7 from '../assets/images/Japanese-Beetles.webp';
import sli8 from '../assets/images/dammanns+garden+co+preparing+soil+spring+studying+garden+soil+with+gloves.jpg';
import sli9 from '../assets/images/pullingweeds.jpg';
import sli10 from '../assets/images/yard-debris-removal-e1615389818729.jpg';
import sli11 from '../assets/images/springFlowers.jpg';
import sli12 from '../assets/images/High-Pressure-Washing-in-Adelaide.jpg';
import sli13 from '../assets/images/landscape lighting installation crew wiring tree uplighting.webp';
import sli14 from '../assets/images/Pondless-Waterfall-and-patio-pond.jpg';
import sli15 from '../assets/images/common-houseplant-problems-yellow-leaves.webp';
import sli16 from '../assets/images/OmniAtriumBed.jpg';
import sli17 from '../assets/images/BLOG_SUB-IMAGES_-_2023-06-27T161836.113_480x480.jpg';
import sli18 from '../assets/images/hedge-trimming-services.jpg';
import sli19 from '../assets/images/Fertilizer-1024x640.jpg';
import sli20 from '../assets/images/garden-pest-control.jpg';


import ch344 from '../assets/images/KIT2.jpg';
import ch3444 from '../assets/images/KIT3.jpg';
// import ch4 from '../asset1/images/demos/demo-14/slider/slide-2.jpg';
// import ch5 from '../asset1/images/demos/demo-26/banners/banner-3.jpg';
// import ch6 from '../asset1/images/demos/demo-26/banners/banner-4.jpg';
import Carousel1 from './Carosel';
import Carousel2 from './Carosel1';
import Carousel3 from './Carosel2';
import Carousel4 from './Carosel3';
import Carousel5 from './Carosel4';
import Carousel6 from './Carosel5';
import Carousel7 from './Carosel6';
import Carousel8 from './Carosel7';
import Ico from './iconBox';
import Cate from './Cate';

// import "react-multi-carousel/lib/styles.css";





const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const  responsive= {
      0: {
          items: 1,
      },
      450: {
          items: 2,
      },
      600: {
          items: 3,
      },
      1000: {
          items: 4,
      },
  }
    const  responsive1= {
      0: {
          items: 2,
      },
      450: {
          items: 2,
      },
      600: {
          items: 3,
      },
      750: {
          items: 4,
      },
      1000: {
          items: 7,
      },
  }
    const  responsive2= {
      0: {
          items: 2,
      },
     
      450: {
          items: 3,
      },
      600: {
          items: 4,
      },
      1000: {
          items: 6,
      },
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const options = {
    // margin: 30,
    // responsiveClass: true,
    // nav: true,
    // dots: false,
    // autoplay: false,
    // navText: ["Prev", "Next"],
    // smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 4,
        },
        1600: {
            items: 4,

        }
    },
};
//     const [options , setoptions] = useState( {
//     loop: true,
//     margin: 10,
//     nav: true,
//     responsive: {
//         0: {
//             items: 1,
//         },
//         600: {
//             items: 3,
//         },
//         1000: {
//             items: 4,
//         },
//     },
// })
const [ServiceType, setServiceType] = useState("") 
const [Location1, setLocation1] = useState("") 
const [name, setname] = useState("") 
const [lname, setlname] = useState("") 
const [email, setemail] = useState("") 
const [subject, setsubject] = useState("") 
const [Message, setMessage] = useState("") 
 
    // var settings = {
    //   infinite: true,
    //   dots: true,
    //   slidesToShow: 5,
    //   slidesToScroll: 1,
    //   lazyLoad: true,
    //   autoplay: true,
    // autoplaySpeed: 2000,
    // };
    useEffect(() =>{

      
 },[])
 const Submitdata = (e)=>{
  e.preventDefault()

  fetch("https://garden-oasis-landscapping-main-back.vercel.app/AMC",{
      method: 'POST' , 
      headers :  {
        "Content-Type" : "application/json" , 
      } , 
      body : JSON.stringify({
        name  ,
        // lname  ,
        email  ,
        phone:subject  ,
        Message  ,
        Location1  ,
        ServiceType  ,
      })
    })
    .then((res)=>res.json())
    .then((res2)  =>{
        console.log(res2)
        if (res2){
          swal("SucessFully Send "  )
          setname ("") 
          setlname("") 
              setemail("") 
              setsubject("") 
              setMessage("") 
          // localStorage.setItem("User" , JSON.stringify("Yes") )
              // props.history.push("/login")
        }
        else if (res2.Error){
          swal(res2.Error )
        }
        else{
          swal("Plz Try Again !"  )
        }
      // console.log(res2)
    })
    .catch((err)=>console.log(err))
  
}

        return (
            
          <div>
<div className="" style={{backgroundImage :'url('+slid+')',backgroundOrigin: "border-box"  ,padding  :"30px"  ,position: "relative"
,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat" }}>


      <div className='page-content pt-4' style={{height: "100%",
    width: "100%",
    top: 0,
    left: 0
    ,position: "absolute"
    ,backgroundColor: "#00160E",
    opacity: 0.6,
}}></div>

          <div className="container">
        
            <div className="row">
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "white",fontSize:"35px",fontWeight:"bold"}}>Garden Maintenance AMC Services at Discounted Prices
</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "white",fontWeight:"600",fontSize:"16px"}}>Villas | Apartments | Offices | Buildings | Hospitals | Homes | Garden

</p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "white"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "white",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        <li>
                        <h3 style={{color : "white",fontWeight:"600",fontSize:"21px"}}>  Dubai  </h3>                  
                          </li>
                        <li>
                        ⭐⭐⭐⭐⭐                 
                               </li>
                        <li>

                        ✔    24x7 Emergency support
             </li>
                        <li>
                        ✔    Unlimited on-call support

                        </li>
                        <li>
                        ✔   Preventive maintenance

                        </li>
                       
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Get a Free Quote</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6">
                <h2 className="title mb-1" style={{color : "white"}}>Schedule a call</h2>{/* End .title mb-2 */}
                {/* <p className="mb-2" style={{color : "white"}}>Use the form below to get Schedule a call</p> */}
                <form  onSubmit={(e)=>Submitdata(e)}>
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cname" style={{color : "white"}}>Full Name</label>
                      <input type="text" className="form-control" id="cname" placeholder="Name *" required value={name} onChange={(e)=>setname(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cemail" style={{color : "white"}}>Email</label>
                      <input type="email" className="form-control" id="cemail" placeholder="Email *" value={email} onChange={(e)=>setemail(e.target.value)}  />
                    </div>{/* End .col-sm-6 */}
                  </div>{/* End .row */}
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cphone" style={{color : "white"}}>Phone</label>
                      <input type="tel" className="form-control" id="cphone" placeholder="Phone"  value={subject} onChange={(e)=>setsubject(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cname" style={{color : "white"}}>Location</label>
                      <select  className="form-control"  value={Location1} onChange={(e)=>setLocation1(e.target.value)}>
                          <option value="Dubai">Dubai</option>
                          <option value="Abu Dhabi">Abu Dhabi</option>
                          <option value="Sharjah">Sharjah</option>
                          <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                          <option value="Ajman">Ajman</option>
                          <option value="Fujairah">Fujairah</option>
                          <option value="Umm Al Quwain">Umm Al Quwain</option>
                         
                        
                        </select>
                    </div>{/* End .col-sm-6 */}
                </div>{/* End .col-sm-6 */}
                  <div className="row">
                        <div className="col-sm-12">
                        <label htmlFor="csubject" style={{color : "white"}}>Service Type</label>
                        {/* <input type="text" className="form-control" id="csubject" placeholder="Subject" /> */}
                        <select  className="form-control" value={ServiceType} onChange={(e)=>setServiceType(e.target.value)}>
                          <option value="Apartment">Apartment</option>
                          <option value="Annual Maintenance">Annual Maintenance</option>
                          <option value="Villa">Villa</option>
                          <option value="Warehouse">Warehouse</option>
                          <option value="Factory">Factory</option>
                          <option value="Office">Office</option>
                          <option value="Ship">Ship</option>
                          <option value="Regular maintenance visits (weekly/bi-weekly/monthly)">Regular Maintenance Visits</option>
                          <option value="Plant replacement">Plant Replacement</option>
                          <option value="Lawn care services (mowing, edging, pruning).">Lawn Care Services</option>
                          <option value="Plant care (pruning, watering, fertilization)">Plant Care</option>
                          <option value="Tree care (pruning, trimming, removal).">Tree Care</option>
                          <option value="Garden design and renovation services.">Garden Design & Renovation</option>
                          <option value="Irrigation system maintenance and repair.">Irrigation System Maintenance & Repair</option>
                          <option value="Pest and disease control.">Pest & Disease Control</option>
                          <option value="Fertilization and soil replacement.">Fertilization & Soil Replacement</option>
                          <option value="Weed control and removal.">Weed Control & Removal</option>
                          <option value="Debris removal and disposal.">Debris Removal & Disposal</option>
                          <option value="Seasonal flower and plant installation.">Seasonal Flower & Plant Installation</option>
                          <option value="Hardscape maintenance (cleaning, sealing, repair).">Hardscape Maintenance</option>
                          <option value="Outdoor lighting maintenance.">Outdoor Lighting Maintenance</option>
                          <option value="Water feature maintenance.">Water Feature Maintenance</option>
                          <option value="Emergency response and repair services.">Emergency Response & Repair</option>
                          <option value="Plantscaping / indoor plants.">Plantscaping / Indoor Plants</option>
                          <option value="Mulching.">Mulching</option>

                        <option value="Other">Other</option>

                        </select>
                        </div>{/* End .col-sm-6 */}
                  </div>{/* End .row */}
                  <label htmlFor="cmessage" style={{color : "white"}}>Message</label>
                  <textarea className="form-control" cols={30} rows={4} id="cmessage" required placeholder="Message *" defaultValue={""}  value={Message}  onChange={(e)=>setMessage(e.target.value)} />
                  <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}>
                    <span>Schedule a call</span>
                    <i className="icon-long-arrow-right" />
                  </button>
                </form>{/* End .contact-form */}
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
      {/* </div> */}
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}


<br />
<br />















{/* 


<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Indoor Plant Care AMC


</h1>
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                Our indoor plant care services in Dubai cater to the specific needs of plants thriving in the city's unique environment. With tailored solutions designed for Dubai's climate, our team ensures that your indoor plants receive the care they need to flourish. From regular watering to precise pruning and proactive pest control, we provide comprehensive care to keep your indoor plants healthy and vibrant.
<br />
<br />
Indoor plants play a vital role in enhancing the ambiance of indoor spaces in Dubai, offering a refreshing touch of greenery amidst the city's hustle and bustle. Our services are aimed at creating healthy and harmonious indoor environments, whether in homes, offices, or commercial spaces. With our expertise and personalized approach, we strive to help your indoor plants thrive in Dubai's dynamic urban landscape, bringing joy and tranquility to your indoor spaces.
</p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-6">
               <img src={sli} alt="" />
              </div>
            </div>
            <hr className="mt-4 mb-5" />
            
          </div>
          
          
        </div>
 */}








<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
            <div className="col-lg-6">
               <img src={sli18} alt="" />
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Regular Maintenance Visits:






</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* Garden Oasis Landscaping understands the importance of regular maintenance visits to ensure the long-term health and beauty of your outdoor environment. Our scheduled maintenance visits in Dubai are designed to keep your landscape looking its best throughout the year, regardless of the season. During these visits, our experienced team will perform a comprehensive range of tasks, including lawn mowing, trimming, pruning, weeding, and debris removal. We also inspect irrigation systems, fertilize plants as needed, and address any emerging issues to prevent them from becoming larger problems. With our regular maintenance visits, you can enjoy a lush, vibrant landscape without the hassle of managing it yourself.


<br />
<br />
 Garden Oasis Landscaping offers regular maintenance visits in Dubai to keep your landscape in pristine condition year-round. Our skilled team will visit your property on a scheduled basis to perform a variety of essential tasks that ensure the health and aesthetics of your outdoor environment. With our regular maintenance visits, you can relax knowing that your landscape is in expert hands and will continue to thrive for years to come. Trust Garden Oasis Landscaping to provide reliable and professional maintenance services tailored to your specific needs.





               */}


<ul >
        
        <li style={{listStyleType:"decimal"}}>Benefit from flexible scheduling: daily, weekly, or monthly visits based on your landscape's requirements.</li>
        
        <br/>
        <li style={{listStyleType:"decimal"}}>Our visit team includes skilled professionals: experienced gardeners, landscape experts, irrigation specialists, and agriculture engineers.</li>
        
        <br/>
        <li style={{listStyleType:"decimal"}}>Receive a comprehensive activity report after each visit, detailing all services performed.</li>
    </ul>
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
             
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}




<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
           
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Free Plant Replacement:





</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* In Dubai's dynamic environment, our Free plant replacement services offer a seamless solution for refreshing and rejuvenating outdoor landscapes. Whether it's replacing aging plants or introducing new varieties to enhance the aesthetic appeal of your outdoor space, our expert team handles the entire process with precision and care. From selecting suitable replacements to seamless installation, we ensure that your outdoor landscape remains lush, vibrant, and inviting.

<br />
<br />
At the core of our Free plant replacement services is a commitment to quality and customer satisfaction. We source high-quality plants from trusted suppliers and ensure that they are expertly installed to thrive in Dubai's climate. With our attention to detail and dedication to excellence, we strive to exceed your expectations and create outdoor landscapes that inspire and delight for years to come. */}
              

              <ul>
        <li style={{listStyleType: "decimal"}}>Free replacement of damaged plants (T&C apply)</li>
        <br />
        <li style={{listStyleType: "decimal"}}>Complimentary flowers & plants after agreement</li>
    </ul>
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6">
               <img src={sli1} alt="" />
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}



        <div className="container icon-boxes-section" style={{background:"#286C53"}}>
                <div className="icon-boxes-container py-4 pb-2 pt-2" style={{background:"#286C53"}}>

                  <div className='row' style={{background:"#286C53"}}>
                 

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        {/* <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Payment &amp; Delivery</h3> */}
                      </div>
                    </div>

<div className="col-lg-5 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 text-uppercase" style={{color : "white",fontSize:"18px",fontWeight:"500"}}>Annual maintenance contract in Abu Dhabi and Dubai
</h3>
                      </div>
                    </div>

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      
                    </div>

<div className="col-lg-3 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px",margin:"20px 0px"}} ><Link to="/contact" >Get a free quote</Link></button>
                      </div>
                    </div>

<div className="col-lg-2 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px"}} ><a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" >Whatsapp</a></button>
                      </div>
                    </div>

                    
                    </div>
                    </div>
                    </div>




<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
            <div className="col-lg-6">
               <img src={sli2} alt="" />
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Lawn Care Services:




</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* Our lawn care services in Dubai are dedicated to maintaining pristine outdoor spaces that enhance the beauty and functionality of your property. With a focus on precision and attention to detail, our expert team ensures that your lawn receives the care it needs to thrive in Dubai's challenging climate. From regular mowing and edging to thorough pruning and fertilization, we tailor our services to meet the unique requirements of your lawn, creating a verdant oasis amidst the desert landscape.
<br />
<br />
Dubai's outdoor environments serve as extensions of our living spaces, providing opportunities for relaxation, recreation, and social gatherings. Our lawn care services are designed to transform outdoor areas into inviting retreats where you can unwind and connect with nature. Whether you're enjoying a leisurely stroll on your lawn or hosting outdoor events with friends and family, our team is committed to maintaining a lush and vibrant turf that enhances your outdoor experience in Dubai. */}



<ul>
    
    <li style={{listStyleType:"decimal"}}>Checking the water quality to ensure optimal hydration for the lawn.</li>
    <br />
    <li style={{listStyleType:"decimal"}}>Applying fertilizers to maintain the lawn's health and promote growth.</li>
    
    <br />
    <li style={{listStyleType:"decimal"}}>Removing damaged plants to keep the lawn looking neat and healthy.</li>
    
    <br />
    <li style={{listStyleType:"decimal"}}>Applying organic chemicals if required to manage pests and diseases.</li>
</ul>


              
              
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
            
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}







<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
           
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Plant Care:







</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* Our plant care services encompass a holistic approach to nurturing and maintaining the health and vitality of your indoor and outdoor plants. Whether it's delicate houseplants or ornamental shrubs in your garden, our expert team provides tailored care that includes regular watering, precise pruning, strategic fertilization, and proactive pest control measures.
                <br />
                <br />
                 With our meticulous attention to detail and deep understanding of plant needs, we ensure that your plants thrive and flourish, adding beauty and tranquility to your surroundings. */}

<ul>
    
    <li style={{listStyleType: "decimal"}}>Regular trimming of plants to maintain their health and shape.</li>
    <br />
    <li style={{listStyleType: "decimal"}}>Thoroughly inspect plants for signs of disease or pests.</li>
    <br />
    <li style={{listStyleType: "decimal"}}>Apply appropriate fertilizers to promote healthy growth if necessary.</li>
</ul>
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6">
               <img src={sli4} alt="" />
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}






        <div className="" style={{padding  :"30px"  }}>


   

<div className="container">

  <div className="row">
  <div className="col-lg-6">
     <img src={sli5} alt="" />
    </div>{/* End .col-lg-6 */}
    <div className="col-lg-6 mb-2 mb-lg-0">
      <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}> Garden Design and Renovation Services


</h1>{/* End .title mb-2 */}
      <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
      {/* In Dubai's ever-evolving landscape, our garden design and renovation services offer a breath of fresh air, transforming spaces into captivating havens of natural beauty. With an understanding of Dubai's unique climate and architectural aesthetics, our team crafts bespoke designs that blend seamlessly with the city's dynamic environment. From lush rooftop gardens to serene courtyard retreats, we bring your outdoor vision to life with meticulous attention to detail and innovative solutions.

<br />
<br />
At the heart of our outdoor garden design and renovation services lies a commitment to excellence and sustainability. We leverage environmentally friendly practices and locally sourced materials to minimize our ecological footprint while maximizing the beauty and functionality of your outdoor spaces. With our expertise and passion for outdoor design, we transform ordinary landscapes into extraordinary retreats, allowing you to experience the beauty of nature in the heart of Dubai. */}



<ul>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Remove the dead plants and replace them with new, healthy plants.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Applying organic chemicals if required to ensure a pest-free garden.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Applying fertilizer if required to enhance plant growth and health.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Cleanup of garden from weeds to maintain a neat and tidy appearance.</li>
</ul>






</p>
      <div className="row">
        <div className="col-sm-7">
          <div className="contact-info">
            {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
            <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
              
              <li>
                <br />
              <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
          <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
          <i className="icon-long-arrow-right" />
        </button>
              </li>
            </ul>{/* End .contact-list */}
          </div>{/* End .contact-info */}
        </div>{/* End .col-sm-7 */}
        
      </div>{/* End .row */}
    </div>{/* End .col-lg-6 */}
  
  </div>{/* End .row */}
  <hr className="mt-4 mb-5" />
  
</div>{/* End .container */}
{/* <div id="map" /> */}
{/* End #map */}
</div>{/* End .page-content */}





<div className="container icon-boxes-section" style={{background:"#286C53"}}>
                <div className="icon-boxes-container py-4 pb-2 pt-2" style={{background:"#286C53"}}>

                  <div className='row' style={{background:"#286C53"}}>
                 

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        {/* <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Payment &amp; Delivery</h3> */}
                      </div>
                    </div>

<div className="col-lg-5 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 text-uppercase" style={{color : "white",fontSize:"18px",fontWeight:"500"}}>Annual maintenance contract in Abu Dhabi and Dubai
</h3>
                      </div>
                    </div>

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      
                    </div>

<div className="col-lg-3 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px",margin:"20px 0px"}} ><Link to="/contact" >Get a free quote</Link></button>
                      </div>
                    </div>

<div className="col-lg-2 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px"}} ><a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" >Whatsapp</a></button>
                      </div>
                    </div>

                    
                    </div>
                    </div>
                    </div>



<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
           
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Irrigation System Installation in Dubai





</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
{/*         
                Garden Oasis Landscaping specializes in irrigation system installation services tailored to the unique needs of outdoor spaces in Dubai. Our expert team designs and installs efficient irrigation systems that deliver the right amount of water to your landscape while conserving resources. From drip irrigation for precise watering to smart controllers for optimal efficiency, we ensure your outdoor plants receive the water they need to thrive in Dubai's climate.
                <br />
                <br />
                 Trust us for professional irrigation system installation that promotes the health and longevity of your landscape. */}


<ul>
    <li  style={{listStyleType:"decimal"}}>Regular irrigation system check-ups to ensure optimal performance.</li>
    <br />
  <li  style={{listStyleType:"decimal"}}>Cleaning of sprinklers and drippers to maintain efficient water distribution.</li>
  <br />
  <li  style={{listStyleType:"decimal"}}>Replacing damaged pipes to prevent leaks and ensure reliable operation.</li>
  <br />
  <li  style={{listStyleType:"decimal"}}>Adjusting the timings of water on automatic systems according to the weather conditions.</li>
</ul>
              
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6">
               <img src={sli3} alt="" />
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}




{/* <div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
            <div className="col-lg-6">
               <img src={sli4} alt="" />
              </div>
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Tree Care:






</h1>
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                In Dubai's challenging climate, our tree care services are essential for preserving the health and longevity of your outdoor trees. From routine pruning to promote growth and structural integrity to precise trimming to maintain shape and aesthetics, our skilled arborists provide expert care tailored to the unique needs of each tree. 
                <br />
                <br />
                we offer services such as hazardous branch removal and preventative measures against pests and diseases, ensuring that your trees remain resilient and majestic in the face of environmental challenges. With our comprehensive tree care services, you can trust that your outdoor landscape will continue to thrive and flourish for years to come.






              
              
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
            
            </div>
            <hr className="mt-4 mb-5" />
            
          </div>
          
          
        </div> */}



        <div className="" style={{padding  :"30px"  }}>


   

<div className="container">

  <div className="row">
 
    <div className="col-lg-6 mb-2 mb-lg-0">
      <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Pest and Disease Control Services:









</h1>
      <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
      {/* Garden Oasis Landscaping in Dubai offers comprehensive pest and disease control services to protect your outdoor environment from harmful invaders and infections. Our expert team employs a combination of proactive measures and targeted treatments to identify, manage, and prevent pest infestations and plant diseases. From insects and rodents to fungal infections and bacterial diseases, we utilize environmentally friendly methods to ensure the health and vitality of your landscape. With our tailored solutions and meticulous attention to detail, you can trust Garden Oasis Landscaping to keep your outdoor space thriving and pest-free year-round.

<br />
      <br />
      In the dynamic climate of Dubai, maintaining a healthy outdoor environment requires proactive pest and disease control measures. Garden Oasis Landscaping specializes in providing expert solutions to combat a wide range of pests and diseases that can threaten the beauty and integrity of your landscape. Our team of professionals is equipped with the knowledge and tools to identify potential threats, implement targeted treatments, and prevent future infestations or outbreaks. Trust Garden Oasis Landscaping for reliable and effective pest and disease control services that prioritize the well-being of your landscape. */}




<ul>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Apply appropriate chemicals to control and eliminate pests.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Regularly check for signs of disease in plants to ensure their health and longevity.</li>
</ul>








    
    </p>
      <div className="row">
        <div className="col-sm-7">
          <div className="contact-info">
          
            <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
              
              <li>
                <br />
              <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
          <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
          <i className="icon-long-arrow-right" />
        </button>
              </li>
            </ul>
          </div>
        </div>
        
      </div>
    </div>
    <div className="col-lg-6">
     <img src={sli20} alt="" />
    </div>
  </div>
  <hr className="mt-4 mb-5" />
  
</div>


</div>


<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
            <div className="col-lg-6">
               <img src={sli19} alt="" />
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Fertilization & Soil Replacement:






</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* Garden Oasis Landscaping offers specialized fertilization and soil replacement services in Dubai to ensure the optimal health and fertility of your outdoor environment. Our team of experts understands the unique requirements of the local soil and climate conditions, and we tailor our fertilization programs to provide the essential nutrients your plants need to thrive. Additionally, we offer soil replacement services to address soil depletion or compaction issues, rejuvenating your landscape and promoting healthy growth. With our professional fertilization and soil replacement services, your outdoor space will flourish with lush vegetation and vibrant colors.

<br />
                <br />
                Achieving and maintaining a healthy, vibrant outdoor environment in Dubai requires careful attention to soil fertility and composition. Garden Oasis Landscaping offers specialized fertilization and soil replacement services to ensure that your landscape receives the nutrients and support it needs to thrive. Our expert team begins by assessing the current condition of your soil and identifying any deficiencies or imbalances. We then develop a customized fertilization plan tailored to the specific needs of your plants and landscape. With our professional fertilization and soil replacement services, you can enjoy a lush and thriving outdoor space that enhances the beauty and value of your property. Trust Garden Oasis Landscaping to nurture your landscape to its full potential. */}







<ul>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Apply fertilizers according to the specific requirements of the plants to promote healthy growth.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Replace soil that is too acidic or sandy to ensure optimal growing conditions for plants.</li>
</ul>



              
              
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
            
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}




{/* 

<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
           
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Garden Design and Renovation Services:









</h1>
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                Our garden design and renovation services offer innovative solutions to transform your outdoor space into a captivating oasis of natural beauty. Whether you're looking to refresh your existing garden or embark on a new landscaping project, our experienced designers work closely with you to bring your vision to life. From conceptualization to execution, we handle every aspect of the design process, incorporating elements such as seasonal blooms, functional layouts, and sustainable practices to create a harmonious and inviting outdoor environment. 
                <br />
                <br />
                With our meticulous attention to detail and creative expertise, we turn ordinary landscapes into extraordinary outdoor retreats that inspire and delight.







              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                    
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-6">
               <img src={sli5} alt="" />
              </div>
            </div>
            <hr className="mt-4 mb-5" />
            
          </div>
          
          
        </div>

 */}








<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
            <div className="col-lg-6">
               <img src={sli9} alt="" />
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Weed Control and Removal:








</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* Our weed control and removal services are designed to keep your outdoor spaces pristine and weed-free. With meticulous attention to detail and effective strategies, our team targets unwanted weeds, preventing them from competing with your plants for nutrients and space. Whether it's hand weeding or the application of safe and environmentally friendly herbicides, we ensure that your landscape remains healthy and aesthetically pleasing. */}





                <ul>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Control weeds by spraying appropriate herbicides where applicable.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Manually remove weeds from narrow patches to maintain a clean garden.</li>
</ul>

              
              
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
            
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}



<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
           
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Debris Removal and Disposal:











</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* Maintaining a clean and clutter-free outdoor environment is essential for the overall appearance and functionality of your landscape. Our debris removal and disposal services are tailored to efficiently clear away any debris, leaves, or branches that may accumulate in your outdoor spaces. With prompt and thorough cleanup, we ensure that your landscape remains tidy and welcoming for your enjoyment. */}


                <ul>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Remove waste from the site after the completion of work to ensure cleanliness.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Clean the area of any kind of trash to maintain a tidy environment.</li>
</ul>



              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6">
               <img src={sli10} alt="" />
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}





<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
            <div className="col-lg-6">
               <img src={sli11} alt="" />
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Seasonal Flower and Plant Installation:










</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                Enhance the beauty of your outdoor spaces with our seasonal flower and plant installation services. Whether you're looking to refresh your landscape with vibrant blooms or add seasonal foliage to complement the changing seasons, our expert team handles every aspect of the installation process. From selecting the perfect plants to planting them in optimal locations, we create stunning displays that bring color and vitality to your outdoor environment.







              
              
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
            
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}





<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
           
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Hardscape Maintenance (Cleaning, Sealing, Repair):













</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* Preserve the integrity and beauty of your hardscape features with our comprehensive maintenance services. From cleaning and sealing to repairing cracks and chips, our skilled technicians ensure that your hardscape elements, such as patios, walkways, and retaining walls, remain in top condition. With regular maintenance, we prolong the lifespan of your hardscape and enhance the overall appeal of your outdoor space. */}



                <ul>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Check for any cracks in the hardscape elements.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Replace broken tiles or fix damaged areas to ensure safety and aesthetics.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Clean all hardscape elements to maintain their appearance and longevity.</li>
</ul>


              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6">
               <img src={sli12} alt="" />
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}






{/* 
<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
            <div className="col-lg-6">
               <img src={sli13} alt="" />
              </div>
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Outdoor Lighting Maintenance:












</h1>
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                Illuminate your outdoor spaces with our outdoor lighting maintenance services. From pathway lights to accent fixtures, we ensure that your outdoor lighting system remains functional and visually appealing. With routine inspections, bulb replacements, and repairs, we keep your outdoor lighting system operating at peak performance, enhancing safety and ambiance in your outdoor environment.








              
              
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                  
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
            
            </div>
            <hr className="mt-4 mb-5" />
            
          </div>
          
          
        </div>
 */}







<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
           
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Water Feature Maintenance (Ponds, Fountains):















</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* Keep your water features sparkling and serene with our water feature maintenance services. Whether you have a pond, fountain, or waterfall, our experienced technicians provide comprehensive maintenance to ensure proper circulation, water quality, and aesthetics. From cleaning and debris removal to pump maintenance and water treatment, we ensure that your water features remain a focal point of beauty and tranquility in your outdoor space. */}




                <ul>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Check for any water leakage to prevent water waste and damage.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Clean up debris from the water to maintain clarity and hygiene.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Ensure smooth water flow to keep the water feature functioning optimally.</li>
</ul>


              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6">
               <img src={sli14} alt="" />
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}



        <div className="container icon-boxes-section" style={{background:"#286C53"}}>
                <div className="icon-boxes-container py-4 pb-2 pt-2" style={{background:"#286C53"}}>

                  <div className='row' style={{background:"#286C53"}}>
                 

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        {/* <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Payment &amp; Delivery</h3> */}
                      </div>
                    </div>

<div className="col-lg-5 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 text-uppercase" style={{color : "white",fontSize:"18px",fontWeight:"500"}}>Annual maintenance contract in Abu Dhabi and Dubai
</h3>
                      </div>
                    </div>

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      
                    </div>

<div className="col-lg-3 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px",margin:"20px 0px"}} ><Link to="/contact" >Get a free quote</Link></button>
                      </div>
                    </div>

<div className="col-lg-2 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px"}} ><a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" >Whatsapp</a></button>
                      </div>
                    </div>

                    
                    </div>
                    </div>
                    </div>



<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
            <div className="col-lg-6">
               <img src={sli15} alt="" />
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Emergency Response and Repair Services:














</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* Unexpected issues can arise in your outdoor landscape, from broken irrigation systems to storm damage. That's why we offer emergency response and repair services to address urgent issues promptly and effectively. With our 24/7 availability and quick response times, you can trust us to restore functionality and safety to your outdoor environment, no matter the time or day. */}







                <ul>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Fix any broken pipe at any time to prevent water loss and damage.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Stabilize and repair trees and palms that have fallen due to strong winds.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Our team will visit promptly in any kind of emergency situation to provide immediate assistance.</li>
</ul>

              
              
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
            
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}










<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
           
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Plantscaping / Indoor Plants:

















</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* Elevate your indoor spaces with our plantscaping services, which bring the beauty of nature indoors. From office buildings to residential spaces, we create customized indoor plant arrangements that enhance ambiance and improve air quality. With our expertise in plant selection, placement, and maintenance, we create indoor environments that promote well-being and productivity. */}





                <ul>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Ensure correct placement of plants to optimize aesthetics and growth.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Add or remove plants according to specific requirements and preferences.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Adjust plant selection based on climate conditions to ensure suitability and health.</li>
</ul>


              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6">
               <img src={sli16} alt="" />
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}














<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
            <div className="col-lg-6">
               <img src={sli17} alt="" />
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Mulching:
















</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* Mulching is an essential practice for maintaining soil moisture, regulating temperature, and suppressing weed growth in your outdoor landscape. Our mulching services provide a protective layer of organic material that enriches the soil and enhances the overall health of your plants. With a variety of mulch options to choose from, we tailor our services to meet your specific needs and preferences, ensuring that your landscape remains healthy and vibrant year-round. */}





                <ul>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Mulching of different types of plants according to their specific requirements.</li>
    
    <br/>
    <li style={{listStyleType:"decimal"}}>Replacement of old mulch and laying new mulch to maintain soil moisture and health.</li>
</ul>








              
              
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
            
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}













<div className="container icon-boxes-section" style={{background:"#286C53"}}>
                <div className="icon-boxes-container py-4 pb-2 pt-2" style={{background:"#286C53"}}>

                  <div className='row' style={{background:"#286C53"}}>
                 

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        {/* <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Payment &amp; Delivery</h3> */}
                      </div>
                    </div>

<div className="col-lg-5 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 text-uppercase" style={{color : "white",fontSize:"18px",fontWeight:"500"}}>Annual maintenance contract in Abu Dhabi and Dubai
</h3>
                      </div>
                    </div>

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      
                    </div>

<div className="col-lg-3 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px",margin:"20px 0px"}} ><Link to="/contact" >Get a free quote</Link></button>
                      </div>
                    </div>

<div className="col-lg-2 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px"}} ><a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" >Whatsapp</a></button>
                      </div>
                    </div>

                    
                    </div>
                    </div>
                    </div>

<br />

      
              
               
        </div>
        )
    
}

export default Home;