import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Ico from './iconBox';

import a1 from '../assets/images/7138294.jpg';
import a2 from '../assets/images/9417839.jpg';
import a3 from '../assets/images/9535087.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
         

        

<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-lg-9 col-sm-12" style={{margin  :"auto"}}>


            <header>
		<h1 style={{fontSize:"3rem",fontWeight:"600"}}>Benefits of Annual Maintenance Contracts for Your Garden Plant in Dubai with Garden Oasis Landscape Service</h1>
	</header>
<img src={a1} alt="Benefits of Annual Maintenance Contracts for Your Garden Plant in Dubai with Garden Oasis Landscape Service" />
<br />
	<section>
		<h2>Introduction</h2>
		<p>As a garden enthusiast in Dubai, you understand the importance of maintaining your garden plants to ensure they thrive and bring beauty to your outdoor space. One of the most effective ways to achieve this is by investing in an annual maintenance contract with Garden Oasis Landscape Service. In this article, we will explore the benefits of annual maintenance contracts for your garden plant in Dubai and how they can help you save time, reduce costs, and increase the overall health and beauty of your garden.</p>
	</section>
    <br />
	<section>
		<h2>Customized Care for Your Garden Plant</h2>
		<p>Garden Oasis Landscape Service offers customized care for your garden plant, providing flexible scheduling for daily, weekly, or monthly visits based on your landscape's requirements. Their team of experts will assess your plant's specific needs and create a personalized maintenance plan tailored to its requirements.</p>
	</section>
    <br />
	<section>
		<h2>Regular Maintenance Visits</h2>
		<p>Regular maintenance visits are essential to ensure the landscape is well-maintained and healthy. Garden Oasis Landscape Service provides free plant replacement in case a plant dies under their care.</p>
	</section>
    <br />
	<section>
		<h2>Key Benefits of an Annual Maintenance Contract</h2>
		<ul>
			<li><strong>Consistent Maintenance and Support</strong>: One of the major advantages of an AMC contract with Garden Oasis Landscape Service is the assurance of consistent maintenance. Regular inspections and servicing mean that your plant's machinery and systems are always in optimal condition. This consistency helps prevent unexpected breakdowns and ensures smooth operations.</li>
			<li><strong>Cost-Effective Solution</strong>: Having an AMC with Garden Oasis Landscape Service saves money over time. Regular maintenance helps find and fix problems before they become big issues, reducing the chance of costly emergency repairs. By paying a set yearly fee, you can plan your budget better and avoid unexpected repair costs.</li>
			<li><strong>Extended Equipment Lifespan</strong>: Routine maintenance under an AMC contract with Garden Oasis Landscape Service can significantly extend the lifespan of your plant's equipment.</li>
		</ul>
	</section>
    
    <div className="container icon-boxes-section" style={{background:"#286C53"}}>
                <div className="icon-boxes-container py-4 pb-2 pt-2" style={{background:"#286C53"}}>

                  <div className='row' style={{background:"#286C53"}}>
                 

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        {/* <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Payment &amp; Delivery</h3> */}
                      </div>
                    </div>

<div className="col-lg-5 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 text-uppercase" style={{color : "white",fontSize:"18px",fontWeight:"500"}}>Gardening and Landscaping Services in Dubai
</h3>
                      </div>
                    </div>

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      
                    </div>

<div className="col-lg-3 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px",margin:"20px 0px"}} ><Link to="/contact" >Get a free quote</Link></button>
                      </div>
                    </div>

<div className="col-lg-2 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px"}} ><a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" >Whatsapp</a></button>
                      </div>
                    </div>

                    
                    </div>
                    </div>
                    </div>



	<section>
		<h2>Conclusion</h2>
		<p>Investing in an annual maintenance contract for your garden plant in Dubai with Garden Oasis Landscape Service is a smart decision that brings numerous benefits. From cost savings and extended equipment lifespan to improved safety and compliance with regulations, an AMC provides a comprehensive solution for maintaining your plant's machinery and systems.</p>
	</section>
    <br />
	<section>
		<h2>Frequently Asked Questions</h2>
		<ul>
			<li><strong>How do you ensure the health and safety of the plants?</strong> Garden Oasis Landscape Service's trained horticulturists regularly monitor the plants for signs of pests, diseases, and other potential issues. They use environmentally-friendly treatments and ensure that the plants are kept in optimal conditions.</li>
			<br /><li><strong>Are there additional costs beyond the annual contract fee?</strong> Garden Oasis Landscape Service's annual maintenance fee covers most of the regular care requirements. However, significant overhauls, specialized treatments, or replacement of large numbers of plants might incur additional costs.</li>
			<br />	<li><strong>Do you offer services for temporary setups like events or exhibitions?</strong> Yes, Garden Oasis Landscape Service provides plant and garden services for temporary setups, ensuring your event or exhibition space looks green and vibrant for the duration.</li>
			<br />	<li><strong>What happens if a plant dies under your care?</strong> Garden Oasis Landscape Service takes full responsibility for the plants under their care. In the unlikely event that a plant dies due to their oversight, they will replace it at no additional cost to you.</li>
            <br /><li><strong>How quickly can you set up a green wall or garden for an event or exhibition?</strong> The lead time depends on the complexity and size of the project. However, Garden Oasis Landscape Service usually requires a minimum of two weeks' notice for events or exhibitions.</li>
            <br /><li><strong>Can I choose the plants for my garden or green wall?</strong> Absolutely! Garden Oasis Landscape Service collaborates closely with their clients to select plants that suit their aesthetic preferences and the specific environmental conditions of the space.</li>
            <br /><li><strong>Do you provide services for indoor plants?</strong> Yes, Garden Oasis Landscape Service offers care andmaintenance services for both indoor and outdoor plants.</li>
		</ul>
	</section>



    <div className="container icon-boxes-section" style={{background:"#286C53"}}>
                <div className="icon-boxes-container py-4 pb-2 pt-2" style={{background:"#286C53"}}>

                  <div className='row' style={{background:"#286C53"}}>
                 

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        {/* <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Payment &amp; Delivery</h3> */}
                      </div>
                    </div>

<div className="col-lg-5 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 text-uppercase" style={{color : "white",fontSize:"18px",fontWeight:"500"}}>Gardening and Landscaping Services in Dubai
</h3>
                      </div>
                    </div>

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      
                    </div>

<div className="col-lg-3 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px",margin:"20px 0px"}} ><Link to="/contact" >Get a free quote</Link></button>
                      </div>
                    </div>

<div className="col-lg-2 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px"}} ><a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Annual Maintenance Contract" >Whatsapp</a></button>
                      </div>
                    </div>

                    
                    </div>
                    </div>
                    </div>





          
          </div>
          </div>
          </div>



          </div>
        )
    }
}

export default About;