// import React ,{useEffect , useState} from 'react'
// import {Link} from "react-router-dom"

// // import swal from 'sweetalert';



// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";



// const Cate = (props) =>{
//     const responsive = {
//         desktop: {
//           breakpoint: { max: 3000, min: 1024 },
//           items: 9,
//           slidesToSlide: 4 // optional, default to 1.
//         },
//         tablet: {
//           breakpoint: { max: 1024, min: 464 },
//           items: 5,
//           slidesToSlide: 3 // optional, default to 1.
//         },
//         mobile: {
//           breakpoint: { max: 464, min: 0 },
//           items: 3,
//           slidesToSlide: 3 // optional, default to 1.
//         }
//       };
    
//       const [MainCatogories , setMainCatogories] = useState([])



//       useEffect(() =>{


//         fetch("https://garden-oasis-landscapping-main-back.vercel.app/MainCatogories",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res2=>res2.json())
//         .then(res3=>{
//             console.log(res3);
//             // setMainCatogories(res3)
            

//             let dat = []
//             res3.map((res55,c)=>{
//                 dat.push(res55)
//             })
//             // console.log(SubClassCategories,asse)
//             console.log(dat)
//             let data = dat.sort((a, b) => {
//                 return a.sort - b.sort;
//             });
//             setMainCatogories(data)
            
//         })

// },[])


// // const changeFruit = (goto) => {
// //   // setCurrentFruit(newFruit)
// //   props.history.push(goto)
// // }



// // function redirect(goto){
// //   if (goto != '') {
// //       props.history.push(goto)
// //       window.location = goto;
// //   }
// // }
// // document.getElementById('cat1').onchange = function(){
// //   var goto = this.value;
// //   redirect(goto);
  
// // };
  
//     return (

//         <Carousel
//   swipeable={false}
//   draggable={true}
//   showDots={true}
//   arrows={true}
//   responsive={responsive}
//   infinite={true}
//   minimumTouchDrag={2}
//   autoPlay = {true}
//   transitionDuration={4500}
// //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
//   autoPlaySpeed={4500}
//   keyBoardControl={true}
//   customTransition="all 2s ease-out"
//   containerClass="carousel-container"
// //   removeArrowOnDeviceType={["tablet", "mobile"]}
// //   deviceType={this.props.deviceType}
//   dotListClass="custom-dot-list-style"
//   itemClass="carousel-item-padding-40-px"
//   style={{height : "158px"}}
//   className='dfsdfklkl'
// >
// {MainCatogories.map((res,i)=>{
//                         return (
//                           <div className="category position-relative">
//                       <div className="category-image">
//                         <Link to={"/categories/"+res.MainCategories+"/all-products"}  >
//                           <img src={res.photo} className="w-100 jkkjkj" width={166} height={160} style={{width :"76%" ,margin: "auto"}} />
//                         </Link>
//                       </div>
//                       <div className="letter-spacing-normal font-size-normal text-center text-uppercase">
//                         <a  className="category-title text-truncate font-weight-normal"><b style={{ padding : "4px 4px",borderRadius : "5px",color : "black"}}>{res.MainCategories}</b> </a>
//                       </div>
//                     </div>
//                           )
//                         })
            
//                         }
          
// </Carousel>

//     )
// }

// export default Cate



import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

// import swal from 'sweetalert';



import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



const Cate = (props) =>{
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 9,
          slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 5,
          slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        }
      };
    
      const [MainCatogories , setMainCatogories] = useState([])



      useEffect(() =>{


        fetch("https://garden-oasis-landscapping-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })

},[])


// const changeFruit = (goto) => {
//   // setCurrentFruit(newFruit)
//   props.history.push(goto)
// }



// function redirect(goto){
//   if (goto != '') {
//       props.history.push(goto)
//       window.location = goto;
//   }
// }
// document.getElementById('cat1').onchange = function(){
//   var goto = this.value;
//   redirect(goto);
  
// };
return (

<div style={{display:"flex",background :"#F9F9F9",margin:"auto",flexFlow: "wrap",justifyContent: "center"}}>


{  
MainCatogories.map((res,i)=>{
                   if(res.MainCategories  === "(AMC) Annual Maintenance Contract"){
                          return (
                            <div className="category position-relative" style={{margin:"10px 3px",border:"1px solid white", background:"white",borderRadius:"10px",padding  :"10px 7px"}}>
                        <div>
                              <Link to={"/categories-main/"+"Annual Maintenance Contract"}   style={{display:"flex",justifyContent :"center",alignItems : "center"}}>
                            <img src={res.photo} className="sdfsfkkj" width={166} height={160} style={{width :"100px",height:"90px",objectFit:"cover" ,margin: "auto",paddingRight: "1.071em"}} alt={res.MainCategories} />
                           <span className="category-title font-weight-normal sdfsfkkj1"  style={{maxWidth:"100px",minWidth:"100px",maxHeight:"70px",display:"inline",}}><b className='sdfsfkkj3' style={{ borderRadius : "5px",color : "black",maxWidth:"100px",height:"70px",fontSize: "1em",paddingRight: "1.071em",lineHeight: 1.1,
        fontWeight: "400"}}>Annual Maintenance Contract        </b> </span>
                          </Link>
                        </div>
                        
                      </div>
                            )
                          }
                   else if(res.MainCategories  === "Landscaping"){
                          return (
                            <div className="category position-relative" style={{margin:"10px 3px",border:"1px solid white", background:"white",borderRadius:"10px",padding  :"10px 7px"}}>
                        <div>
                              <Link to={"/categories-main/"+res.MainCategories}   style={{display:"flex",justifyContent :"center",alignItems : "center"}}>
                            <img src={res.photo} className="sdfsfkkj" width={166} height={160} style={{width :"100px",height:"90px",objectFit:"cover" ,margin: "auto",paddingRight: "1.071em"}} alt={res.MainCategories} />
                           <span className="category-title font-weight-normal sdfsfkkj1"  style={{maxWidth:"100px",minWidth:"100px",maxHeight:"70px",display:"inline",}}><b className='sdfsfkkj3' style={{ borderRadius : "5px",color : "black",maxWidth:"100px",height:"70px",fontSize: "1em",paddingRight: "1.071em",lineHeight: 1.1,
        fontWeight: "400"}}>{res.MainCategories}</b> </span>
                          </Link>
                        </div>
                        
                      </div>
                            )
                          }
                          else{
                            return (
                              <div className="category position-relative" style={{margin:"10px 3px",border:"1px solid white", background:"white",borderRadius:"10px",padding  :"10px 7px"}}>
                          <div>
                                <Link to={"/categories/"+res.MainCategories+"/all-products"}   style={{display:"flex",justifyContent :"center",alignItems : "center"}}>
                              <img src={res.photo} className="sdfsfkkj" width={166} height={160} style={{width :"100px",height:"90px",objectFit:"cover" ,margin: "auto",paddingRight: "1.071em"}} alt={res.MainCategories} />
                             <span className="category-title font-weight-normal sdfsfkkj1"  style={{maxWidth:"100px",minWidth:"100px",maxHeight:"70px",display:"inline",}}><b className='sdfsfkkj3' style={{ borderRadius : "5px",color : "black",maxWidth:"100px",height:"70px",fontSize: "1em",paddingRight: "1.071em",lineHeight: 1.1,
          fontWeight: "400"}}>{res.MainCategories}</b> </span>
                            </Link>
                          </div>
                          
                        </div>
                              )

                          }
                          })
               }
</div>
            
                        
          

    )
}

export default Cate