import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import OwlCarousel from 'react-owl-carousel';  
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";




import swal from 'sweetalert';
// import Loader from "react-loader-spinner";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Container, Card, Row, Col } from "react-bootstrap";
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import logo1 from '../assets/images/logo1.png';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import KIT1 from '../assets/images/KIT.jpg';
// import slid2 from '../assets/images/slid2.webp';


// import ch1 from '../asset1/images/demos/demo-26/banners/banner-5.jpg';
// import ch2 from '../asset1/images/demos/demo-26/banners/banner-6.jpg';
// import ch3 from '../asset1/images/demos/demo-26/bg-1.jpg';

import slid1 from '../assets/images/slider (1).jpg';
import slid2 from '../assets/images/slider (2).jpg';
import slid3 from '../assets/images/slider (3).jpg';
import slid4 from '../assets/images/slider (4).jpg';
import slid5 from '../assets/images/slider (5).jpg';
import slid6 from '../assets/images/slider6.jpg';
import slid from '../assets/images/villa-landscaping-services-near-me.webp';
import sli1 from '../assets/images/difference-between-lawn-care-and-landscaping.jpg';
import sli2 from '../assets/images/back-yard-fire-pit-deck-bc1d716b-f8ac9935591e4699828efa7821bb6c17.webp';
import sli3 from '../assets/images/20170525_161104.webp';
import sli4 from '../assets/images/page-trees-and-shrubs.jpg';
import sli5 from '../assets/images/inseticide-for-pests-scaled-1-2048x1316.webp';
import sli6 from '../assets/images/High-Pressure-Washing-in-Adelaide.jpg';
import sli7 from '../assets/images/unnamed.jpg';
import sli8 from '../assets/images/Softscape-H49-710x575.jpg';


import ch344 from '../assets/images/KIT2.jpg';
import ch3444 from '../assets/images/KIT3.jpg';
// import ch4 from '../asset1/images/demos/demo-14/slider/slide-2.jpg';
// import ch5 from '../asset1/images/demos/demo-26/banners/banner-3.jpg';
// import ch6 from '../asset1/images/demos/demo-26/banners/banner-4.jpg';
import Carousel1 from './Carosel';
import Carousel2 from './Carosel1';
import Carousel3 from './Carosel2';
import Carousel4 from './Carosel3';
import Carousel5 from './Carosel4';
import Carousel6 from './Carosel5';
import Carousel7 from './Carosel6';
import Carousel8 from './Carosel7';
import Ico from './iconBox';
import Cate from './Cate';

// import "react-multi-carousel/lib/styles.css";





const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const  responsive= {
      0: {
          items: 1,
      },
      450: {
          items: 2,
      },
      600: {
          items: 3,
      },
      1000: {
          items: 4,
      },
  }
    const  responsive1= {
      0: {
          items: 2,
      },
      450: {
          items: 2,
      },
      600: {
          items: 3,
      },
      750: {
          items: 4,
      },
      1000: {
          items: 7,
      },
  }
    const  responsive2= {
      0: {
          items: 2,
      },
     
      450: {
          items: 3,
      },
      600: {
          items: 4,
      },
      1000: {
          items: 6,
      },
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const options = {
    // margin: 30,
    // responsiveClass: true,
    // nav: true,
    // dots: false,
    // autoplay: false,
    // navText: ["Prev", "Next"],
    // smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 4,
        },
        1600: {
            items: 4,

        }
    },
};
//     const [options , setoptions] = useState( {
//     loop: true,
//     margin: 10,
//     nav: true,
//     responsive: {
//         0: {
//             items: 1,
//         },
//         600: {
//             items: 3,
//         },
//         1000: {
//             items: 4,
//         },
//     },
// })
const [ServiceType, setServiceType] = useState("") 
const [Location1, setLocation1] = useState("") 
const [name, setname] = useState("") 
const [lname, setlname] = useState("") 
const [email, setemail] = useState("") 
const [subject, setsubject] = useState("") 
const [Message, setMessage] = useState("") 
 
    // var settings = {
    //   infinite: true,
    //   dots: true,
    //   slidesToShow: 5,
    //   slidesToScroll: 1,
    //   lazyLoad: true,
    //   autoplay: true,
    // autoplaySpeed: 2000,
    // };
    useEffect(() =>{

      
 },[])
 const Submitdata = (e)=>{
  e.preventDefault()

  fetch("https://garden-oasis-landscapping-main-back.vercel.app/AMC",{
      method: 'POST' , 
      headers :  {
        "Content-Type" : "application/json" , 
      } , 
      body : JSON.stringify({
        name  ,
        // lname  ,
        email  ,
        phone:subject  ,
        Message  ,
        Location1  ,
        ServiceType  ,
      })
    })
    .then((res)=>res.json())
    .then((res2)  =>{
        console.log(res2)
        if (res2){
          swal("SucessFully Send "  )
          setname ("") 
          setlname("") 
              setemail("") 
              setsubject("") 
              setMessage("") 
          // localStorage.setItem("User" , JSON.stringify("Yes") )
              // props.history.push("/login")
        }
        else if (res2.Error){
          swal(res2.Error )
        }
        else{
          swal("Plz Try Again !"  )
        }
      // console.log(res2)
    })
    .catch((err)=>console.log(err))
  
}

        return (
            
          <div>
<div className="" style={{backgroundImage :'url('+slid+')',backgroundOrigin: "border-box"  ,padding  :"30px"  ,position: "relative"
,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat" }}>


      <div className='page-content pt-4' style={{height: "100%",
    width: "100%",
    top: 0,
    left: 0
    ,position: "absolute"
    ,backgroundColor: "#00160E",
    opacity: 0.6,
}}></div>

          <div className="container">
        
            <div className="row">
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "white",fontSize:"35px",fontWeight:"bold"}}>Best Tree Supplier in Dubai UAE

</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "white",fontWeight:"600",fontSize:"16px"}}>Villas | Apartments | Offices | Buildings | Hospitals | Homes | Garden

</p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "white"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "white",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        <li>
                        <h3 style={{color : "white",fontWeight:"600",fontSize:"21px"}}> Dubai UAE   </h3>                  
                          </li>
                        <li>
                        ⭐⭐⭐⭐⭐                 
                               </li>
                        <li>

                        ✔    Landscaping Service
             </li>
                        <li>

                        ✔   Garden Maintenance Contracts
             </li>
                        <li>
                        ✔    Indoor and Outdoor Plants


                        </li>
                      
                        <li>
          
                        ✔    24x7 Emergency support

                        </li>
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Landscaping Service" > <span>Get a Free Quote</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6">
                <h2 className="title mb-1" style={{color : "white"}}>Schedule a call</h2>{/* End .title mb-2 */}
                {/* <p className="mb-2" style={{color : "white"}}>Use the form below to get Schedule a call</p> */}
                <form  onSubmit={(e)=>Submitdata(e)}>
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cname" style={{color : "white"}}>Full Name</label>
                      <input type="text" className="form-control" id="cname" placeholder="Name *" required value={name} onChange={(e)=>setname(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cemail" style={{color : "white"}}>Email</label>
                      <input type="email" className="form-control" id="cemail" placeholder="Email *" value={email} onChange={(e)=>setemail(e.target.value)}  />
                    </div>{/* End .col-sm-6 */}
                  </div>{/* End .row */}
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cphone" style={{color : "white"}}>Phone</label>
                      <input type="tel" className="form-control" id="cphone" placeholder="Phone"  value={subject} onChange={(e)=>setsubject(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cname" style={{color : "white"}}>Location</label>
                      <select  className="form-control" value={Location1} onChange={(e)=>setLocation1(e.target.value)}>
                      <option value="Dubai">Dubai</option>
                          <option value="Abu Dhabi">Abu Dhabi</option>
                          <option value="Sharjah">Sharjah</option>
                          <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                          <option value="Ajman">Ajman</option>
                          <option value="Fujairah">Fujairah</option>
                          <option value="Umm Al Quwain">Umm Al Quwain</option>
                         
                        
                        </select>
                    </div>{/* End .col-sm-6 */}
                </div>{/* End .col-sm-6 */}
                  <div className="row">
                        <div className="col-sm-12">
                        <label htmlFor="csubject" style={{color : "white"}}>Service Type</label>
                        {/* <input type="text" className="form-control" id="csubject" placeholder="Subject" /> */}
                        <select  className="form-control"  value={ServiceType} onChange={(e)=>setServiceType(e.target.value)}>
                          <option value="Apartment">Apartment</option>
                          <option value="Annual Maintenance">Annual Maintenance</option>
                          <option value="Villa">Villa</option>
                          <option value="Warehouse">Warehouse</option>
                          <option value="Factory">Factory</option>
                          <option value="Office">Office</option>
                          <option value="Ship">Ship</option>
                        <option value="Other">Other</option>
                        </select>
                        </div>{/* End .col-sm-6 */}
                  </div>{/* End .row */}
                  <label htmlFor="cmessage" style={{color : "white"}}>Message</label>
                  <textarea className="form-control" cols={30} rows={4} id="cmessage" required placeholder="Message *" defaultValue={""}  value={Message}  onChange={(e)=>setMessage(e.target.value)} />
                  <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}>
                    <span>Schedule a call</span>
                    <i className="icon-long-arrow-right" />
                  </button>
                </form>{/* End .contact-form */}
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
      {/* </div> */}
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}


<br />
<br />



















<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
            <div className="col-lg-6">
               <img src={sli2} alt="" />
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}> Garden Design & Installation in Dubai



</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* Elevate your outdoor space with Garden Oasis Landscaping's garden design and installation services in Dubai. Our experienced designers collaborate closely with you to create customized outdoor sanctuaries that reflect your vision and lifestyle. From refreshing existing gardens to designing new landscapes from scratch, we handle every aspect of the process with creativity and expertise. Incorporating elements such as seasonal blooms, functional layouts, and sustainable practices, we transform ordinary landscapes into extraordinary outdoor retreats that inspire and delight.
                <br />
                <br />
                 With Garden Oasis Landscaping, your outdoor oasis in Dubai awaits. */}


<ul>
    <br/>
    <li style={{listStyleType:"decimal"}}>Create theme-based garden designs tailored to your preferences.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Install natural or artificial grass for a lush and green landscape.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Plant shrubs, plants, trees, and palms according to the available space and desired aesthetic.</li>
</ul>



</p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Landscaping Service" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
            
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}






        <div className="" style={{padding  :"30px"  }}>


   

<div className="container">

  <div className="row">
 
    <div className="col-lg-6 mb-2 mb-lg-0">
      <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Hardscaping in Dubai






</h1>{/* End .title mb-2 */}
      <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
      {/* Enhance the beauty and functionality of your outdoor space with Garden Oasis Landscaping's hardscape services in Dubai. From patios and walkways to retaining walls and outdoor kitchens, our skilled craftsmen design and install stunning hardscape features that complement your landscape. 
      <br />
      <br />
      We use high-quality materials and meticulous craftsmanship to create durable and visually appealing hardscape elements that withstand Dubai's climate and enhance the overall aesthetic of your outdoor environment. Trust us to transform your outdoor space with professional hardscape services. */}





<ul>
    <br/>
    <li style={{listStyleType:"decimal"}}>Design and construct patios and decks to create outdoor living spaces for relaxation and entertainment.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Create pathways and walkways to enhance accessibility and guide movement throughout the landscape.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Install fences and gates for privacy, security, and boundary delineation.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Build garden walls to add structure, define spaces, and create visual interest in the landscape.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Incorporate water features to introduce tranquility and a sense of serenity into your outdoor environment.</li>
</ul>


    
    
    
    </p>
      <div className="row">
        <div className="col-sm-7">
          <div className="contact-info">
            {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
            <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
              
              <li>
                <br />
              <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
          <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Landscaping Service" > <span>Request for a solution</span></a>
          <i className="icon-long-arrow-right" />
        </button>
              </li>
            </ul>{/* End .contact-list */}
          </div>{/* End .contact-info */}
        </div>{/* End .col-sm-7 */}
        
      </div>{/* End .row */}
    </div>{/* End .col-lg-6 */}
    <div className="col-lg-6">
     <img src={sli6} alt="" />
    </div>{/* End .col-lg-6 */}
  </div>{/* End .row */}
  <hr className="mt-4 mb-5" />
  
</div>{/* End .container */}
{/* <div id="map" /> */}
{/* End #map */}
</div>{/* End .page-content */}



        <div className="container icon-boxes-section" style={{background:"#286C53"}}>
                <div className="icon-boxes-container py-4 pb-2 pt-2" style={{background:"#286C53"}}>

                  <div className='row' style={{background:"#286C53"}}>
                 

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        {/* <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Payment &amp; Delivery</h3> */}
                      </div>
                    </div>

<div className="col-lg-5 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 text-uppercase" style={{color : "white",fontSize:"18px",fontWeight:"500"}}>Gardening and Landscaping Services in Dubai
</h3>
                      </div>
                    </div>

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      
                    </div>

<div className="col-lg-3 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px",margin:"20px 0px"}} ><Link to="/contact" >Get a free quote</Link></button>
                      </div>
                    </div>

<div className="col-lg-2 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px"}} ><a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Landscaping Service" >Whatsapp</a></button>
                      </div>
                    </div>

                    
                    </div>
                    </div>
                    </div>



                    <div className="" style={{padding  :"30px"  }}>


   

<div className="container">

  <div className="row">
 
    <div className="col-lg-6 mb-2 mb-lg-0">
      <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Softscaping in Dubai






</h1>{/* End .title mb-2 */}
      <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
      {/* Softscaping is one of the most exciting, creative and interesting stages of garden improvement. The greening process is applicable to the gardens of various sizes and shapes.
<br />
Fresh lawns with soft green grass, eye-pleasing flower beds with bright, beautiful flowers, beautiful lawns and ridges, spectacular and expressive hedges – all these techniques of landscape design can transform any backyard into a wonderful oasis.
<br />
If you want your garden to delight you throughout the years, we recommend you to use our services.
<br />
<b> Stages of Softscaping:</b>
<br />
The first phase of the project is indicating the location of all green space in the garden;
<br />
The following is the organization of watering systems, lighting and arrangement of artificial reservoirs;
<br />
This is followed by a direct process for landscape gardening: settling in garden beds, lawns, Alpine gardens, rose gardens;
<br />
If necessary, we create a hedge;
<br />
The final step is the installation of decorative sculptures and elements of landscape architecture.
<br />
Our landscaping services will transform a garden space into a wonderful place for rest and relaxation. */}




<ul>
    <br/>
    <li style={{listStyleType:"decimal"}}>Install hedging to create privacy, define spaces, and enhance aesthetics.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Introduce container plants to add greenery and versatility to outdoor areas.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Implement ground covers to suppress weeds, retain soil moisture, and beautify landscapes.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Create artificial reservoirs to add visual interest and promote relaxation.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Design and plant flower beds to introduce color, fragrance, and beauty to your outdoor space.</li>
</ul>

    
    
    
    </p>
      <div className="row">
        <div className="col-sm-7">
          <div className="contact-info">
            {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
            <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
              
              <li>
                <br />
              <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
          <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Landscaping Service" > <span>Request for a solution</span></a>
          <i className="icon-long-arrow-right" />
        </button>
              </li>
            </ul>{/* End .contact-list */}
          </div>{/* End .contact-info */}
        </div>{/* End .col-sm-7 */}
        
      </div>{/* End .row */}
    </div>{/* End .col-lg-6 */}
    <div className="col-lg-6">
     <img src={sli8} alt="" />
    </div>{/* End .col-lg-6 */}
  </div>{/* End .row */}
  <hr className="mt-4 mb-5" />
  
</div>{/* End .container */}
{/* <div id="map" /> */}
{/* End #map */}
</div>{/* End .page-content */}




{/* <div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
            <div className="col-lg-6">
               <img src={sli4} alt="" />
              </div>
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Tree & Shrubs Care in Dubai




</h1>
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                Ensure the health and vitality of your outdoor trees and shrubs with Garden Oasis Landscaping's expert care services in Dubai. Our skilled arborists provide comprehensive tree and shrub care, including pruning, trimming, and fertilization, tailored to the specific needs of each plant. With our proactive approach to pest and disease management, we protect your trees and shrubs from common threats in Dubai's environment.
                <br />
                <br />
                 Trust us to preserve the beauty and integrity of your outdoor greenery with professional tree and shrub care services.



              
              
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                     
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Landscaping Service" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
            
            </div>
            <hr className="mt-4 mb-5" />
            
          </div>
          
          
        </div>

 */}







{/* 

<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
           
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Seasonal Cleanups in Dubai 









</h1>
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                Keep your outdoor landscape looking its best year-round with Garden Oasis Landscaping's seasonal cleanup services in Dubai. Our expert team provides thorough and efficient cleanup services to remove debris, leaves, and other seasonal clutter from your outdoor space. Whether it's preparing your landscape for the summer heat or tidying up after the winter season, we ensure your outdoor space remains pristine and inviting throughout the year. 
                <br />
                <br />
                Trust us for reliable and professional seasonal cleanup services that maintain the beauty and functionality of your landscape.







              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                   
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Landscaping Service" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-6">
               <img src={sli7} alt="" />
              </div>
            </div>
            <hr className="mt-4 mb-5" />
            
          </div>
          
          
        </div>
 */}







<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
            <div className="col-lg-6">
               <img src={sli3} alt="" />
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Irrigation System Installation in Dubai





</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
        
                {/* Garden Oasis Landscaping specializes in irrigation system installation services tailored to the unique needs of outdoor spaces in Dubai. Our expert team designs and installs efficient irrigation systems that deliver the right amount of water to your landscape while conserving resources. From drip irrigation for precise watering to smart controllers for optimal efficiency, we ensure your outdoor plants receive the water they need to thrive in Dubai's climate.
                <br />
                <br />
                 Trust us for professional irrigation system installation that promotes the health and longevity of your landscape. */}


<ul>
    <br/>
    <li style={{listStyleType:"decimal"}}>Install automatic irrigation systems for efficient and hassle-free watering.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Set up sprinkler irrigation systems to evenly distribute water across the landscape.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Implement drip irrigation systems for precise and targeted watering, ideal for water conservation.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Utilize surface irrigation systems for large areas with even terrain.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Implement subsurface irrigation systems for efficient water delivery directly to plant roots.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Provide manual irrigation systems for customizable watering control and flexibility.</li>
</ul>
              
              
              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Landscaping Service" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
            
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}





<div className="" style={{padding  :"30px"  }}>


   

          <div className="container">
        
            <div className="row">
           
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}>Pest Control in Dubai







</h1>{/* End .title mb-2 */}
                <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
                {/* Combat pests and protect your outdoor landscape with Garden Oasis Landscaping's effective pest control services in Dubai. Our experienced technicians employ safe and environmentally friendly methods to eliminate pests while minimizing harm to beneficial insects and plants. Whether it's controlling common garden pests or addressing specific issues unique to Dubai's climate, we provide targeted solutions to keep your outdoor space pest-free.
                <br />
                <br />
                 With Garden Oasis Landscaping, you can enjoy a healthy and thriving landscape free from the nuisance of pests. */}


<ul>
    <br/>
    <li style={{listStyleType:"decimal"}}>Utilize organic pest control methods for environmentally-friendly solutions.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Employ inorganic pest control methods for effective eradication of pests.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Implement closed area pest control measures for indoor spaces and enclosed environments.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Execute open area pest control strategies for outdoor spaces and open environments.</li>
</ul>


              </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
                      <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
                        
                        <li>
                          <br />
                        <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
                    <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Landscaping Service" > <span>Request for a solution</span></a>
                    <i className="icon-long-arrow-right" />
                  </button>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6">
               <img src={sli5} alt="" />
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}








        <div className="" style={{padding  :"30px"  }}>


   

<div className="container">

  <div className="row">
  <div className="col-lg-6">
     <img src={sli1} alt="" />
    </div>{/* End .col-lg-6 */}
    <div className="col-lg-6 mb-2 mb-lg-0">
      <h1 className="title mb-1" style={{color : "rgb(76 99 53)",fontSize:"38px",fontWeight:"bold"}}> Lawn Maintenance in Dubai


</h1>{/* End .title mb-2 */}
      <p className="mb-3" style={{color : "black",fontWeight:"500",fontSize:"16px"}}>
      {/* At Garden Oasis Landscaping, we offer top-notch lawn maintenance services tailored to the unique climate and conditions of Dubai. Our team ensures your outdoor turf remains healthy, vibrant, and lush year-round through precision mowing, thorough edging, and regular fertilization. We understand the importance of a well-maintained lawn in enhancing the beauty and functionality of your outdoor space, and we are committed to providing meticulous care to meet your needs and exceed your expectations. */}
{/* <br />
<br />
Additionally, our sustainable lawn care practices prioritize environmental conservation while maintaining the beauty of your outdoor spaces. With eco-friendly fertilizers and water-efficient irrigation systems, we minimize our ecological footprint while ensuring your lawn remains resilient in Dubai's dynamic environment. Trust Garden Oasis Landscaping for comprehensive lawn maintenance services that keep your outdoor turf looking its best. */}




<ul>
    <br/>
    <li style={{listStyleType:"decimal"}}>Perform lawn renovation to rejuvenate and enhance the appearance of your lawn.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Regularly mow the lawn to maintain an optimal height for healthy growth.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Fertilize and weed the lawn to promote lush, green grass and suppress weed growth.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Manage pests and diseases to protect the health and vitality of your lawn.</li>
    <br/>
    <li style={{listStyleType:"decimal"}}>Edge and trim the lawn to define boundaries and create a neat, manicured appearance.</li>
</ul>





</p>
      <div className="row">
        <div className="col-sm-7">
          <div className="contact-info">
            {/* <h3 style={{color : "black"}}>Abu Dhabi I Dubai
</h3> */}
            <ul className="contact-list" style={{color : "black",fontWeight:"600",fontSize:"17px",maxWidth:"300px"}}>
              
              <li>
                <br />
              <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"#286C53", color :"white"}}  >
          <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Landscaping Service" > <span>Request for a solution</span></a>
          <i className="icon-long-arrow-right" />
        </button>
              </li>
            </ul>{/* End .contact-list */}
          </div>{/* End .contact-info */}
        </div>{/* End .col-sm-7 */}
        
      </div>{/* End .row */}
    </div>{/* End .col-lg-6 */}
   
  </div>{/* End .row */}
  <hr className="mt-4 mb-5" />
  
</div>{/* End .container */}
{/* <div id="map" /> */}
{/* End #map */}
</div>{/* End .page-content */}











<div className="container icon-boxes-section" style={{background:"#286C53"}}>
                <div className="icon-boxes-container py-4 pb-2 pt-2" style={{background:"#286C53"}}>

                  <div className='row' style={{background:"#286C53"}}>
                 

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        {/* <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Payment &amp; Delivery</h3> */}
                      </div>
                    </div>

<div className="col-lg-5 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 text-uppercase" style={{color : "white",fontSize:"18px",fontWeight:"500"}}>Gardening and Landscaping Services in Dubai
</h3>
                      </div>
                    </div>

<div className="col-lg-1 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      
                    </div>

<div className="col-lg-3 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px",margin:"20px 0px"}} ><Link to="/contact" >Get a free quote</Link></button>
                      </div>
                    </div>

<div className="col-lg-2 col-sm-12 icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    
                      <div className="icon-box-content">
                      <button className="btn btn-outline-primary-2 btn-minwidth-sm" style={{background:"white", color :"white",borderRadius:"20px",color:"#286C53",fontWeight:"bold",fontSize:"20px"}} ><a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscaping*! I need more info about Landscaping Service" >Whatsapp</a></button>
                      </div>
                    </div>

                    
                    </div>
                    </div>
                    </div>

<br />

      
              
               
        </div>
        )
    
}

export default Home;