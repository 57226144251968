import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const Contact = (props)=>  {


    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);




        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Privacy Policy</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "30px 30px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div style={{fontWeight :"bold"}}>
                                {/* <h2>This Privacy Policy</h2> */}
                                <h3>Privacy Policy</h3>

<p>At Garden Oasis Landscaping, we take pride in ensuring our customers' privacy and protecting them from low-quality products and sellers. Our mission is to offer only genuine products in their original packaging on our platform. We work diligently to provide you with the largest selection of authentic and brand new products of the highest quality.</p>

<p>We are dedicated to safeguarding the privacy of our customers when they use our services. We take all necessary measures to protect your personal information. Your use of our website implies that you have agreed to adhere to our Privacy Policy and Terms. We are committed to protecting the privacy of our website visitors.</p>

<p>We may collect tracking information when you visit or use our website. Additionally, we automatically receive and record information on our server logs from your browser, including your IP address, cookie information, and the page you requested.</p>

<p>Whether you create an account with us or shop as a guest, the information we collect includes your name, address, contact number, email address, bank details, and/or credit/debit card information. This information will primarily be used for the following purposes:</p>

<ul>
    <li>To fulfill, process, and ship your orders</li>
    <li>To contact you for customer service and support</li>
    <li>Sending you information and offers for marketing purposes</li>
    <li>To learn about your browsing and shopping experience at GardenOasisLandscaping.com</li>
</ul>

<p>We guarantee that no information will be disclosed to external entities for any purpose, and your information will solely be used by Garden Oasis Landscaping.</p>

<p>We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.</p>

                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;